import { useState, useEffect } from "react";
import { Button, Col } from "react-bootstrap";
import GenericCard from "../../common/GenericCard";
import { MARCAS } from "../../../constants/constants";
import { useHistory, useLocation } from "react-router-dom";
import WhatsappContactButton from "../../common/WhatsappContactButton";
import Swal from "sweetalert2";

export default function useUsados() {
  const [modelos, setModelos] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [items, setItems] = useState(localStorage);
  const [usadosOtrasMarcas, setUsadosOtrasMarcas] = useState([]);
  const [usadosToyota, setUsadosToyota] = useState([]);
  const [usadosToyotaCertificados, setUsadosToyotaCertificados] = useState([]);
  const [marcaSeleccionada, setMarcaSeleccionada] = useState(MARCAS.OTRAS);
  const [modelosSeleccionados, setModelosSeleccionados] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [cargaInicial, setCargaInicial] = useState(true);
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const location = useLocation();
  let history = useHistory();

  const queryParameters = new URLSearchParams(location.search);

  /* localStorage.clear(); */

  let product = location.state
    ? location.state.product
    : items.length > 0
    ? items[0]
    : history.push("/");

  const handleButtonClick = (product) => {
    localStorage.setItem("items", JSON.stringify(product));
    /* localStorage.clear(); */
  };

  //console.log(localStorage, "localStorage");

  useEffect(() => {
    const fetchData = async () => {
      setSpinner(true);
      var url = "https://store.lenken.com.ar/lenkenapi/autos/usados";

      try {
        const response = await fetch(url);
        const modelos = await response.json();
        setModelos(modelos);
        setUsadosOtrasMarcas(
          modelos.filter((modelo) => modelo.marca !== MARCAS.TOYOTA)
        );
        setUsadosToyota(
          modelos.filter(
            (modelo) =>
              modelo.marca === MARCAS.TOYOTA && modelo.certificado === "NO"
          )
        );
        setUsadosToyotaCertificados(
          modelos.filter(
            (modelo) =>
              modelo.marca === MARCAS.TOYOTA && modelo.certificado === "SI"
          )
        );
      } catch (error) {
        console.error("Error fetching data: ", error);
        const catchError = async () => {
          try {
            var url = "https://store.lenken.com.ar/lenkenapi/autos/usados";
            const response = await fetch(url);
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            const modelos = await response.json();
            console.log(process.env.REACT_APP_BREVO_API_KEY);
            console.log("Data fetched:", modelos);
          } catch (error) {
            console.error("Error fetching data:", error);
            try {
              const response = await fetch(
                "https://api.brevo.com/v3/smtp/email",
                {
                  method: "POST",
                  headers: {
                    "api-key": process.env.REACT_APP_BREVO_API_KEY,
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    sender: { email: "clamadevelopers@gmail.com" },
                    to: [{ email: "clamadevelopers@gmail.com" }],
                    subject: "🚨 Fallo en USADOS LENKEN",
                    htmlContent: `<p>El backend no está respondiendo.</p>`,
                  }),
                }
              );
              if (response.ok) {
                console.log("Correo de error enviado!");
              } else {
                console.error(
                  "Error enviando el email:",
                  await response.json()
                );
              }
            } catch (err) {
              console.error("Error en la petición a Brevo:", err);
            }
          }
        };
        catchError();
      } finally {
        setSpinner(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const productoParam =
    modelos &&
    modelos.find(
      (modelo) =>
        modelo.id_interno === parseInt(queryParameters.get("idInterno"))
    );

  //console.log(productoParam, "productoParam");

  useEffect(() => {
    product && setMarcaSeleccionada(product.marca);
  }, [product]);

  useEffect(() => {
    if (!productoParam) {
      const items = JSON.parse(localStorage.getItem("items"));
      if (items) {
        setItems([items]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (marcaSeleccionada) {
      case MARCAS.OTRAS:
        setModelosSeleccionados(
          productoParam ? [productoParam] : usadosOtrasMarcas
        );
        break;
      case MARCAS.TOYOTA:
        if (usadosToyota.length === 0 && cargaInicial) {
          setMarcaSeleccionada(MARCAS.TOYOTA_CERTIFICADO);
          /* setCargaInicial(false); */
        } else {
          setModelosSeleccionados(
            productoParam ? [productoParam] : usadosToyota
          );
        }
        break;
      case MARCAS.TOYOTA_CERTIFICADO:
        setModelosSeleccionados(
          productoParam ? [productoParam] : usadosToyotaCertificados
        );
        break;
      default:
        setModelosSeleccionados(
          productoParam
            ? [productoParam]
            : usadosOtrasMarcas.filter(
                (item) => item.marca === marcaSeleccionada
              )
        );
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    productoParam,
    marcaSeleccionada,
    usadosOtrasMarcas,
    usadosToyota,
    usadosToyotaCertificados,
  ]);

  useEffect(() => {
    if (productoParam) {
      setMarcaSeleccionada(
        productoParam.certificado === "SI"
          ? MARCAS.TOYOTA_CERTIFICADO
          : productoParam.marca
      );
      localStorage.setItem("items", JSON.stringify(productoParam));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productoParam]);

  const buttonsToRender = () => {
    let botones;

    switch (marcaSeleccionada) {
      case MARCAS.TOYOTA:
      case MARCAS.TOYOTA_CERTIFICADO:
        botones = [
          { marca: MARCAS.TOYOTA },
          { marca: MARCAS.TOYOTA_CERTIFICADO },
        ];
        break;
      case MARCAS.OTRAS:
        botones = modelos.filter((modelo, index, self) => {
          return (
            self.findIndex((m) => m.marca.trim() === modelo.marca.trim()) ===
              index && modelo.marca !== MARCAS.TOYOTA
          );
        });

        break;
      default:
        botones = modelos.filter((modelo, index, self) => {
          return (
            self.findIndex((m) => m.marca.trim() === modelo.marca.trim()) ===
              index && modelo.marca !== MARCAS.TOYOTA
          );
        });
    }

    return (
      <>
        {marcaSeleccionada !== MARCAS.TOYOTA &&
          marcaSeleccionada !== MARCAS.TOYOTA_CERTIFICADO && (
            <Button
              size="sm"
              variant="light"
              style={{ borderRadius: "0px" }}
              className="mx-1 align-text-top my-1"
              onClick={() => {
                history.push("/usados/catalogo");
                setMarcaSeleccionada("OTRAS MARCAS");
              }}
            >
              TODAS
            </Button>
          )}
        {botones &&
          botones.map((modelo, i) => (
            <Button
              size="sm"
              variant="light"
              style={{ borderRadius: "0px" }}
              className="mx-1 align-text-top my-1"
              key={"id" + i}
              onClick={() => {
                history.push("/usados/catalogo");
                setMarcaSeleccionada(modelo.marca);
                if (
                  modelo.marca === MARCAS.TOYOTA &&
                  usadosToyota.length === 0
                ) {
                  Swal.fire({
                    text: "En este momento solo contamos con usados certificados.",
                    icon: "info",
                    confirmButtonText: "Aceptar",
                  });
                }
              }}
            >
              {modelo.marca}
            </Button>
          ))}
      </>
    );
  };

  const genericCardstoRender = () => {
    return modelosSeleccionados.length > 0 ? (
      <>
        {modelosSeleccionados.map((modelo, i) => (
          <Col sm={5} md={5} lg={3} className="m-1" key={"id" + i}>
            <GenericCard
              title={modelo.version}
              text={modelo.tipo}
              image={modelo.foto0}
              imageFixedPath="https://store.lenken.com.ar/img/"
              price={Intl.NumberFormat("es-AR", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(modelo.precio)}
              buttonBackgroundColor="black"
              action="reservar"
              to="/usados/catalogo/gastos"
              product={modelo}
              handleButtonClick={handleButtonClick}
              //--- atributos de autos usados
              from="usados"
              km={modelo.kms}
              anio={modelo.anio}
              transmision={modelo.transmision}
              link="catalogo/fotos"
              whatsappButton={
                <WhatsappContactButton origen="usados" modelo={modelo} />
              }
            />
          </Col>
        ))}
      </>
    ) : (
      <Col className="text-center my-4" xs={12} md={{ span: 4 }}>
        <h4>No hay modelos disponibles para su seleccion</h4>
      </Col>
    );
  };

  const handleChangeFilter = (event) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    let modelosBuscados = [];

    switch (marcaSeleccionada) {
      case MARCAS.TOYOTA:
        modelosBuscados = usadosToyota;
        break;
      case MARCAS.TOYOTA_CERTIFICADO:
        modelosBuscados = usadosToyotaCertificados;
        break;
      case MARCAS.OTRAS:
        modelosBuscados = usadosOtrasMarcas;
        break;
      default:
        modelosBuscados = usadosOtrasMarcas.filter(
          (item) => item.marca === marcaSeleccionada
        );
        break;
    }

    const results = modelosBuscados.filter((modelo) =>
      modelo.modelo.toLowerCase().includes(search.toLowerCase())
    );

    setSearchResults(results);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    setSearch("");
  }, [marcaSeleccionada]);

  useEffect(() => {
    setModelosSeleccionados(searchResults);
  }, [searchResults]);

  return {
    modelos,
    spinner,
    handleButtonClick,
    items,
    genericCardstoRender,
    buttonsToRender,
    marcaSeleccionada,
    handleChangeFilter,
    search,
  };
}
